import React, { useEffect, useState } from "react";
import StateInput from "components/StateInput";
import Button from "components/Button";
import Translator from "components/Translator";
import useCurrentUser from "hooks/useCurrentUser";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { UserType } from "Contexts/AuthContext";
import {
  AddShippingAddressRequest,
  NewShippingAddressRequest,
  ShippingAddressSuggestionsRequest,
} from "apis";
import { AddressType } from "types";
import DeliveryInfoModal from "../../Shared/DeliveryInfoModal";
import { isEmpty } from "lodash";

const ShippingFormSchema = Yup.object().shape({
  first_name: Yup.string(),
  last_name: Yup.string(),
  phone: Yup.string(),
  street_1: Yup.string().required("Required"),
  street_2: Yup.string(),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
});

type NewShippingAddressFormProps = {
  user?: UserType;
  onCancel: () => void;
  onSuccessSubmit: (values: {}, pickUpAvailAble: boolean) => void;
  onInvalidAddress: () => void;
};

const NewShippingAddressForm = ({
  onSuccessSubmit,
}: NewShippingAddressFormProps) => {
  const user = useCurrentUser();
  const [address, setAddress] = useState<AddressType>({});
  const [suggestedAddress, setSuggestedAddress] = useState<AddressType>({});
  const [selectedState, setSelectedState] = useState<string>("");
  const [smartyResults, setSmartyResults] = useState<[]>([]);
  const [isSmartyDropdown, setIsSmartyDropDown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [smartyState, setSmartyState] = useState<string>(selectedState);
  const [showDeliveryInfoModal, setShowDeliveryInfoModal] =
    useState<boolean>(false);
  const [deliveryInfoModalType, setDeliveryInfoModalType] = useState(
    "suggested" || "not found"
  );

  const handleStateChange = (code: any, setFieldValue: any) => {
    setSelectedState(code);

    const newAddress = { state: code };

    setAddress({ ...address, ...newAddress });
    setFieldValue("state", code);
  };

  const smartyAutocomplete = (e: any) => {
    const value = e.target.value;

    setLoading(true);

    if (value.length > 3) {
      const searchText: string = value;
      const maxRecords: number = 10;
      const country: string = "US";

      setIsSmartyDropDown(true);
      getShippingSuggestions(searchText, "");
    } else if (value.length === 0) {
      setIsSmartyDropDown(false);
    }
  };

  const formatAddressValue = (data: any) => {
    return `${data.street_line} ${data.secondary} (${data.entries}) ${data.city} ${data.state} ${data.zipcode}`
  }

  const getShippingSuggestions = (searchText: string, selected: string) => {
    ShippingAddressSuggestionsRequest(searchText, selected)
    .then((res) => {
      setSmartyResults(res.data.result);
      setLoading(false);
    })
    .catch((err) => console.log(err));
  }

  const handleResultClick = (data: any, setFieldValue: any) => {
    if (data.entries > 1) {
      setLoading(true);
      const formattedValue = formatAddressValue(data);
      getShippingSuggestions(data.street_line, formattedValue);
    } else {
      const newAddress = {
        ...address,
        street_1: data?.street_line,
        street_2: data?.secondary,
        city: data?.city,
        state: data?.state,
        zip: data.zipcode || "",
      };

      setAddress({ ...address, ...newAddress });
      setSelectedState(data.state);
      setFieldValue("street_1", data.street_line);
      setFieldValue("street_2", data.secondary);
      setFieldValue("city", data.city);
      setFieldValue("state", data.state);
      setFieldValue("zip", data.zipcode || "");
      setSmartyState(data.state);
      handleStateChange(data.state, setFieldValue);

      setIsSmartyDropDown(false);
      setLoading(false);
  }
  };

  const handleNewShippingAddress = (values: any) => {
    NewShippingAddressRequest(values)
      .then((res) => {
        const {
          invalid,
          invalid_address,
          invalid_secondary_number,
          suggested_address,
          suggestion,
        } = res.data.result;

        if (!isEmpty(suggested_address)) {
          setSuggestedAddress(suggested_address);

          if (
            values.street_1 === suggested_address.street_1 &&
            values.city === suggested_address.city &&
            values.state === suggested_address.state &&
            values.zip === suggested_address.zip &&
            values.street_2 == suggested_address.street_2 &&
            !invalid_secondary_number
          ) {
            handleSubmitAddress(values);
          } else {
            setDeliveryInfoModalType("suggested");
            setShowDeliveryInfoModal(true);
          }
        } else if (!isEmpty(invalid_address)) {
          setDeliveryInfoModalType("not found");
          setShowDeliveryInfoModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitAddress = (values: AddressType) => {
    AddShippingAddressRequest(values)
      .then((res) => {
        const shippingAddress = res.data.shipping_address;
        const pickUpAvailAble = shippingAddress.pick_up_available;
        onSuccessSubmit(shippingAddress, pickUpAvailAble);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          ...address,
          first_name: address.first_name
            ? address.first_name
            : user?.first_name,
          last_name: address.last_name ? address.last_name : user?.last_name,
          phone: address.phone ? address.phone : user?.mobile_phone,
          street_1: "",
          street_2: "",
          city: "",
          state: address.state ? address.state : "",
          zip: "",
        }}
        validationSchema={ShippingFormSchema}
        onSubmit={(values) => {
          setAddress({ ...address, ...values });

          handleNewShippingAddress(values);
        }}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <>
            <Form className="shipping-address__form">
              <Field
                className="input with-icon"
                name="first_name"
                type="hidden"
                value={values.first_name}
              />
              <Field
                className="input with-icon"
                name="last_name"
                type="hidden"
                value={values.last_name}
              />
              <Field
                className="input with-icon"
                name="phone"
                type="hidden"
                value={values.phone}
              />

              <div className="form-group">
                <label className="input-label">
                  <Translator path="formik.street" />{" "}
                </label>
                <div className="input-group row mx-0">
                  <Field
                    className="input with-icon no-mouseflow mouseflow-hidden"
                    name="street_1"
                    onKeyUp={(e: any) => smartyAutocomplete(e)}
                    onFocus={() => setIsSmartyDropDown(true)}
                    onBlur={() => setIsSmartyDropDown(loading)}
                  />
                  <div className="input__icon user"></div>
                </div>
                <div className="search-addresses">
                  {isSmartyDropdown && (
                    <ul className="melissa-drowdown">
                      {loading && (
                        <span className="p-3">
                          <Translator path="checkout.shipping.loading" />
                        </span>
                      )}

                      {!loading && smartyResults?.length === 0 ? (
                        <span className="p-3 mouseflow-hidden">
                          <Translator path="checkout.shipping.noResults" />
                        </span>
                      ) : (
                        <>
                          {smartyResults.map((result: any, key: number) => (
                            <>
                              <li
                                key={key}
                                className="no-mouseflow mouseflow-hidden"
                              >
                                <button
                                  className="no-mouseflow mouseflow-hidden"
                                  type="button"
                                  onMouseDown={() =>
                                    handleResultClick(result, setFieldValue)
                                  }
                                >
                                  {result.street_line} {result.secondary}{" "}
                                  {result.entries > 0 && (
                                    <>
                                      {`(${result.entries} or more entries)`}{" "}
                                    </>
                                  )}
                                  {result.city}, {result.state} {result.zipcode}
                                </button>
                              </li>
                            </>
                          ))}
                        </>
                      )}
                    </ul>
                  )}
                </div>

                {errors.street_1 && touched.street_1 ? (
                  <div className="input-error">{errors.street_1}</div>
                ) : null}
              </div>

              <div className="form-group">
                <label className="input-label">
                  <Translator path="formik.aptUnitSuite" />
                </label>
                <div className="input-group row mx-0">
                  <Field
                    className="input with-icon no-mouseflow mouseflow-hidden"
                    name="street_2"
                  />
                  <div className="input__icon user"></div>
                </div>
                {errors.street_2 && touched.street_2 ? (
                  <div className="input-error">{errors.street_2}</div>
                ) : null}
              </div>

              <div className="form-group">
                <label className="input-label">
                  <Translator path="formik.city" />
                </label>
                <div className="input-group row mx-0">
                  <Field
                    className="input with-icon no-mouseflow mouseflow-hidden"
                    name="city"
                  />
                  <div className="input__icon user"></div>
                </div>
                {errors.city && touched.city ? (
                  <div className="input-error">{errors.city}</div>
                ) : null}
              </div>

              <div className="form-group">
                <label className="input-label">
                  <Translator path="formik.state" />
                </label>
                <Field name="state">
                  {({ field }: any) => {
                    return (
                      <StateInput
                        value={selectedState}
                        smartyState={smartyState}
                        handleStateChange={(code: string) =>
                          handleStateChange(code, setFieldValue)
                        }
                        {...field}
                      />
                    );
                  }}
                </Field>
                {errors.state && touched.state ? (
                  <div className="input-error">{errors.state}</div>
                ) : null}
              </div>

              <div className="form-group">
                <label className="input-label">
                  <Translator path="formik.zip" />
                </label>
                <div className="input-group row mx-0">
                  <Field
                    className="input with-icon no-mouseflow mouseflow-hidden"
                    name="zip"
                  />
                  <div className="input__icon user"></div>
                </div>
                {errors.zip && touched.zip ? (
                  <div className="input-error">{errors.zip}</div>
                ) : null}
              </div>

              <Button
                classes="submit-btn mt-5 ga-registration-event"
                type="submit"
                color="secondary"
                fullWidth
              >
                <Translator path="checkout.shipping.saveAddressButtonText" />
              </Button>
            </Form>
            <DeliveryInfoModal
              show={showDeliveryInfoModal}
              setShow={setShowDeliveryInfoModal}
              selectedAddress={address}
              suggestedAddress={suggestedAddress}
              handleSubmitAddress={(values) => handleSubmitAddress(values)}
              setAddress={setAddress}
              deliveryInfoModalType={deliveryInfoModalType}
            />
          </>
        )}
      </Formik>
    </>
  );
};
export default NewShippingAddressForm;
