import { useRollbarPerson } from "@rollbar/react";
import axios from "axios";
import useCurrentUser from "hooks/useCurrentUser";
import { isEmpty } from "lodash";
import { UserType, UserPasswordType } from "../Contexts/AuthContext";
import { CartType } from "../Contexts/CartContext";
import { AddressType } from "../types";
import { facebookFBP } from "helpers/FbApiDataExtraction";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const requestAxios = axios.create({
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    Accept: "*/*",
  },
  baseURL: BASE_URL,
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const currentUser = useCurrentUser();

    if (!isEmpty(currentUser)) useRollbarPerson(currentUser);
    Promise.reject(error);
  }
);

requestAxios.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("authToken");
    const fbp = facebookFBP();

    if (token) {
      config.headers["AuthToken"] = token;
    }

    if (fbp) {
      config.headers["Fbp"] = fbp;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/**
 * Request for initiating user login (and assigning guest cart if any)
 * @param {string} values - Values needed for proceeding with login
 * @param {string} values.email - The user's email
 * @param {string} values.password - The user's password
 * @param {string} values.cart_token - Guest cart's token for assigning to user
 */
const LoginRequest = async (values: {
  email: string;
  password: string;
  cart_token: string;
  become_token: string;
  mobile_phone: string;
  otp_code?: string;
  is_otp_request?: boolean;
}) => {
  return await requestAxios.post("auth/create", { user: values });
};

const LoginViaMobileRequest = async (values: { phone: string }) => {
  return await requestAxios.put("auth/mobile_or_email_login", {
    user: {
      mobile_phone: values.phone,
    },
  });
};

const LoginViaEmailRequest = async (values: { email: string }) => {
  return await requestAxios.put("auth/mobile_or_email_login", {
    user: {
      email: values.email,
    },
  });
};

const EmailCodeRequest = async (values: { email: string }) => {
  return await requestAxios.put("auth/email_code", {
    user: {
      email: values.email,
    },
  });
};

const LogoutRequest = async () => {
  return await requestAxios.delete("auth/logout", {});
};

const MelissaAutocompleterequest = async (
  searchText: string,
  maxRecords: number,
  country: string
) => {
  const melissaId = process.env.REACT_APP_MELISSA_ID;
  const baseURL = process.env.REACT_APP_MELISSA_BASE_URL;

  return await axios.get(
    `${baseURL}?ff=${searchText}&format=json&id=${melissaId}&maxrecords=${maxRecords}&country=${country}`
  );
};

/**
 * Request for initiating user registration (and assigning guest cart if any)
 * @param {string} values - Values needed for proceeding with login
 * @param {string} values.first_name - User's first name
 * @param {string} values.last_name - User's last name
 * @param {string} values.email - User's email
 * @param {string} values.password - User's password
 * @param {number} values.landing_page_id - Determines user is a Sildenafil-user or Tadalafil-user
 * @param {string} values.cart_token - Guest cart's token for assigning to user
 */
const SignupRequest = async (values: {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  landing_page_id: number;
  cart_token: string;
  referrer?: string;
  source?: string;
  price_version?: string;
}) => {
  return await requestAxios.post("registrations/create", { user: values });
};

const CreateFacebookUserRequest = async (values: {
  oauth_res: {};
  cart_token: string | undefined;
}) => {
  return await requestAxios.post("omniauth/facebook", {
    user: values,
  });
};

const FinishOauthSetupRequest = async (values: UserType) => {
  return await requestAxios.put("omniauth/finish_setup", { user: values });
};

/**
 * Request for verifying email existence (used before showing signup form)
 * @param {string} values - Values needed for proceeding with login
 * @param {string} values.email_verifier - User's email
 */
const VerifyEmailRequest = async (values: { email_verifier: string }) => {
  return await requestAxios.get("verify_emails", {
    params: {
      user: { email: values.email_verifier },
    },
  });
};

const ValidateSendGridEmailRequest = async (values: { email_verifier: string }) => {
  return await requestAxios.get("validate_emails", {
    params: {
      user: { email: values.email_verifier },
    },
  });
};

const GetProductVariantRequest = async () => {
  return await requestAxios.get("product_variants");
};

const GetProductVariantsRequest = async (
  cartId?: number,
  isMarketingCart?: boolean
) => {
  return await requestAxios.get("products", {
    params: {
      cart_id: cartId,
      is_marketing_products: isMarketingCart ? "marketing" : "",
    },
  });
};

const GetUserRequest = async (signal: any) => {
  return await requestAxios.get("users/get_user", { signal });
};

const UpdateUserRequest = async (user: UserType) => {
  return await requestAxios.put(`users/${user.id}`, { user });
};

const UpdateUserPasswordRequest = async (password: UserPasswordType) => {
  return await requestAxios.put("passwords", { password });
};

const ForgotPasswordRequest = async (email: string) => {
  return await requestAxios.post("forgot_passwords/reset_password", {
    email: email,
  });
};

const ValidateResetPasswordTokenRequest = async (token: string) => {
  return await requestAxios.get("forgot_passwords/validate_password_token", {
    params: {
      token: token,
    },
  });
};

const SetNewPasswordRequest = async (values: {
  password: string;
  passwordConfirmation: string;
  resetToken: string;
}) => {
  return await requestAxios.post("forgot_passwords/set_new_password", {
    user: {
      password: values.password,
      password_confirmation: values.passwordConfirmation,
      reset_password_token: values.resetToken,
    },
  });
};

const WelcomePageRequest = async (cartId: string, CartToken: string) => {
  return await requestAxios.put("checkout/welcome", {
    cart_id: cartId,
    cart_token: CartToken,
  });
};

const MarketingNextStepRequest = async (cartId: number | undefined) => {
  return await requestAxios.put("checkout/marketing/next_step", {
    cart_id: cartId,
  });
};

// intro questions

const FetchIntroQuestionsRequest = async (visitUuid: string | undefined) => {
  return await requestAxios.get("ui/intro_questions", {
    params: {
      visit_id: visitUuid,
    },
  });
};

const FetchStatesRequest = async () => {
  return await requestAxios.get("states");
};

const FetchVisitConsentDataRequest = async (
  pocketmedUuid: string,
  cartId: number
) => {
  return await requestAxios.get(
    "ui/visit_consents",{
      params: {
        pocketmed_uuid: pocketmedUuid,
        cart_id: cartId,
      },
  });
};

const SendIntroQuestionsToPocketmedRequest = async (cartId: string) => {
  const CartToken = localStorage.getItem("CartToken");

  return await requestAxios.put("checkout/intro_questions", {
    cart_id: cartId,
    cart_token: CartToken,
  });
};

const CreateVisitRequest = async (
  visitData: {},
  pocketmedUuid: string | undefined
) => {
  return await requestAxios.post('ui/visit_consents', {
    visit: visitData,
    pocketmed_uuid: pocketmedUuid,
  });
};

const UpdateIntroQuestionsRequest = async (
  visitUuid: any,
  questionnaireData: any
) => {
  return await requestAxios.post("ui/intro_questions", {
    questionnaire_data: questionnaireData,
    visit_uuid: visitUuid,
  });
};

const VisitConsentNextStepRequest = async (
  user: any,
  visitUuid: any,
  cartId: string
) => {
  return await requestAxios.put(`checkout/visit_consents/${cartId}`, {
    user: user,
    visit_uuid: visitUuid,
    cart_id: cartId,
  });
};

const VisitIntroNextStepRequest = async (cartId: number) => {
  return await requestAxios.put(`checkout/visit_intro/${cartId}`, {
    cart_id: cartId,
  });
};

const PatientInfoNextStepRequest = async (cartId: any) => {
  return await requestAxios.put(`checkout/patient_info/${cartId}`, {
    cart_id: cartId,
  });
};

const FetchQuestionsRequest = async (visitUuid: string) => {
  return await requestAxios.get(
    `ui/questions`,{
      params: {
        visit_uuid: visitUuid,
      },
  }
  );
};

const QuestionnaireBackRequest = async (
  visitUuid: any,
  activeQuestionLabel: any
) => {
  return await requestAxios.post(
    `ui/back_questions`,
    {
      active_que_label: activeQuestionLabel,
      visit_uuid: visitUuid,
    }
  );
};

const QuestionnaireNextStepRequest = async (cartId: number, values: {}) => {
  return await requestAxios.put(`checkout/visit_consultation/${cartId}`, {
    cart_id: cartId,
    checkout: values,
  });
};

const ProductDetailNextStepRequest = async (cartId: number) => {
  return await requestAxios.put(`checkout/product_detail/${cartId}`, {
    cart_id: cartId,
  });
};

const ProductDetailConversionRequest = async (cartId: number) => {
  return await requestAxios.put(`checkout/product_detail/${cartId}/convert`, {
    cart_id: cartId,
  });
};

const ShippingNextStepRequest = async (cartId: number) => {
  return await requestAxios.put(`checkout/shippings/${cartId}`, {
    cart_id: cartId,
  });
};

const IdUploadNextStepRequest = async (cartId: number, skipId: boolean) => {
  return await requestAxios.put(`checkout/id_upload/${cartId}`, {
    cart_id: cartId,
    skip_id: skipId,
  });
};

const SelfieNextStepRequest = async (cartId: number, skipSelfie: boolean) => {
  return await requestAxios.put(`checkout/selfie_upload/${cartId}`, {
    cart_id: cartId,
    skip_selfie: skipSelfie,
  });
};

const NoCheckupNextStepRequest = async (cartId: any) => {
  return await requestAxios.put(`checkout/no_checkup/${cartId}`, {
    cart_id: cartId,
  });
};

const NoBloodPressureNextStepRequest = async (cartId: any) => {
  return await requestAxios.put(`checkout/no_blood_pressure/${cartId}`, {
    cart_id: cartId,
  });
};

const PaymentNextStepRequest = async (cartId: any) => {
  return await requestAxios.put(`checkout/order_verification/${cartId}`, {
    cart_id: cartId,
  });
};

// Questionnaire Response to PocketMed
const SendUserResponseToPocketmedRequest = async (
  visitUuid: any,
  values: {}
) => {
  return await requestAxios.post(
    `ui/responses`,{
      visit_uuid: visitUuid,
      values: values
    }
  );
};

const FetchDosespotDataRequest = async (values: {}) => {
  return await requestAxios.get("/dose_spot", {
    params: values,
  });
};

// Cart Apis

const CreateCartRequest = async (values: {
  variant: string;
  cart_token: string;
  qty?: string;
}) => {
  return await requestAxios.post("carts", { cart: values });
};

const CreateSlugCartRequest = async (
  values: {
    slug?: string;
    qty: string | null;
    cart_token: string;
  },
  utm?: {}
) => {
  return await requestAxios.post("carts", { cart: values, utm });
};

const FetchCartRequest = async () => {
  const token = localStorage.getItem("CartToken");

  return await requestAxios.get("carts", {
    params: {
      cart_token: token,
    },
  });
};

const FetchRecentMostCartRequest = async () => {
  return await requestAxios.get("users/recent_most_cart");
};

const UpdateCartRequest = async (cart: CartType) => {
  return await requestAxios.put(`carts/${cart.id}`, { cart });
};

const DestroyCartRequest = async (cartId: number | undefined) => {
  return await requestAxios.delete(`carts/${cartId}`);
};

const FetchShippingAddressesRequest = async (cartId: number) => {
  return await requestAxios.get(`checkout/shippings`, {
    params: {
      cart_id: cartId,
    },
  });
};

const FetchDeliveryOptonsRequest = async () => {
  return await requestAxios.get("delivery_options");
};

const AddShippingAddressRequest = async (address: AddressType) => {
  return await requestAxios.post("shipping_addresses", address);
};

const NewShippingAddressRequest = async (address: AddressType) => {
  return await requestAxios.get("shipping_addresses/new", {
    params: {
      address: address,
    },
  });
};

const ShippingAddressSuggestionsRequest = async (prefix: string, selected: string) => {
  return await requestAxios.get("shipping_addresses/suggest", {
    params: {
      prefix: prefix,
      selected: selected
    },
  });
};

const UpdateShippingAddressRequest = async (address: AddressType) => {
  return await requestAxios.put(`shipping_addresses/${address.id}`, address);
};

const DeleteShippingAddressRequest = async (address: AddressType) => {
  return await requestAxios.delete(`shipping_addresses/${address.id}`);
};

const FetchIdFromPockedmedRequest = async (
  cartId: number,
  pocketmedUuid: string | undefined
) => {
  return await requestAxios.get(
    `ui/id_cards`, {
      params: {
        cart_id: cartId,
        pocketmed_uuid: pocketmedUuid
      }
    }
  );
};

const UploadVisitIdRequest = async (
  cartId: number,
  pocketmedUuid: string | undefined,
  formData: FormData
) => {
  formData.append("cart_id", cartId.toString());
  formData.append("pocketmed_uuid", pocketmedUuid || "");
  
  return await requestAxios.post(
    `ui/id_cards`, 
    formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }
  );
};

const UploadVisitSelfieRequest = async (
  cartId: number,
  pocketmedUuid: string | undefined,
  formData: FormData
) => {
  formData.append("cart_id", cartId.toString());
  formData.append("pocketmed_uuid", pocketmedUuid || "");
  
  return await requestAxios.post(
    `ui/selfies`,
    formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }
  );
};

const FetchSelfieFromPockedmedRequest = async (
  cartId: number,
  pocketmedUuid: string | undefined
) => {
  return await requestAxios.get(
    `ui/selfies`, {
      params: {
        cart_id: cartId,
        pocketmed_uuid: pocketmedUuid
      }
    }
  );
};

const GetPocketmedStatusesRequest = async (
  pocketmedUuid: string | undefined
) => {
  return await requestAxios.get(`ui/statuses`,
    {
      params: {
        pocketmed_uuid: pocketmedUuid
      }
    }
  );
};

const FetchMedicalVisitsRequest = async (pocketmedUuid: string | undefined) => {
  return await requestAxios.get(`ui/medical_visits`,
    {
      params: {
        pocketmed_uuid: pocketmedUuid
      }
    }
  );
};

const FetchCardsRequest = async () => {
  return await requestAxios.get("/credit_cards");
};

const CreateCardRequest = async (paymentProfileParams: {
  billing_address: {};
  opaque_data: {};
}) => {
  return await requestAxios.post("/credit_cards", paymentProfileParams);
};

const SelectCardRequest = async (paymentProfileId: string) => {
  return await requestAxios.put(`/select_cards`, {
    payment_profile_id: paymentProfileId,
  });
};

const DeleteCardRequest = async (paymentProfileId: string) => {
  return await requestAxios.delete(`/credit_cards/${paymentProfileId}`);
};

const ApplyDiscountRequest = async (values: {
  cart_id: number;
  code: string;
}) => {
  return await requestAxios.post("/discounts", values);
};

const RemoveDiscountRequest = async (cartId: number) => {
  return await requestAxios.delete(`/discounts/${cartId}`);
};

const ResumeCartRequest = async () => {
  return await requestAxios.get("/resume");
};

const FetchOrderRequest = async (orderId?: string | number) => {
  if (orderId) {
    return await requestAxios.get(`/orders/${orderId}`);
  } else {
    return await requestAxios.get(`/orders`);
  }
};

const OrderPaymentRequest = async (orderId: string | number) => {
  return await requestAxios.put(`/orders/${orderId}/complete_order`);
};

// Order Update Request
const OrderUpdateRequest = async (values: {
  orderId: string | number;
  deliveryType: string | undefined;
  addressId: number | undefined;
}) => {
  return await requestAxios.put(`/orders/${values.orderId}`, {
    order: {
      order_id: values.orderId,
      delivery_type: values.deliveryType,
      shipping_address_id: values.addressId,
    },
  });
};

// Retake Requests
const RetakePhotosStatusRequest = async (cartId: number) => {
  return await requestAxios.get("/retake_photos", {
    params: { cart_id: cartId },
  });
};

const ResetPhotosRetakeRequest = async (
  pocketmedUuid: string | undefined,
  visitUuid: string | undefined
) => {
  return await requestAxios.post(
    `ui/reset_photos`, {
      visit_uuid: visitUuid,
      pocketmed_uuid: pocketmedUuid,
    }
  );
};

const RetakePhotosNextStepRequest = async (cartID: number | undefined) => {
  return await requestAxios.put(`/retake_photos/${cartID}`);
};

// Refills
const FetchOrderRefillsRequest = async () => {
  return await requestAxios.get("account/order_refill");
};

const CreateRefillOrderRequest = async (values: {}) => {
  return await requestAxios.post("account/order_refill", {
    cart: values,
  });
};

const FetchRefillCartRequest = async () => {
  return await requestAxios.get("refill_carts");
};

const UpdateRefillOrderRequest = async (cartID: number | undefined) => {
  return await requestAxios.put(`account/order_refill/${cartID}`);
};

const DeleteRefillOrderCartRequest = async (
  prescriptionID: number | undefined
) => {
  return await requestAxios.delete(`account/order_refill/${prescriptionID}`);
};

const FetchRefillContactStageCartRequest = async () => {
  return await requestAxios.get("refill_carts/contact_stage_cart");
};
const GetCurrentOrders = async () => {
  return await requestAxios.get("account/current_orders");
};

const GetOrdersHistory = async () => {
  return await requestAxios.get("account/orders_history");
};

const GetOrderStatusRequest = async () => {
  return await requestAxios.get("account/order_status");
};

// chat modal iframe
const GetChatModalIframUrl = async () => {
  return await requestAxios.get("chat_modal");
};

const MarkMessagesAsRead = async (pocketmedUuid: string | undefined) => {
  return await requestAxios.post(
    `ui/read_messages`,{
      pocketmed_uuid: pocketmedUuid
    }
  );
};

// Resend Email Confirmation Request
const ResendEmailConfirmationRequest = async () => {
  return await requestAxios.post("user_email_verification");
};

// User Email Confirmation Request
const UserEmailConfirmationRequest = async (token: string) => {
  return await requestAxios.put(`user_email_verification/${token}`);
};
// crm product info details
const GetProductDetailsRequest = async (isMarketingProducts: string) => {
  return await requestAxios.get("products", {
    params: {
      is_marketing_products: isMarketingProducts,
    },
  });
};

// Reset Questionnaire
const ResetQuestionnaireRequest = async (VisitUuid: string | undefined) => {
  return await requestAxios.post(
    `ui/reset_questions`,
    {
      visit_uuid: VisitUuid,
    }
  );
};

// Cart Previous Step
const CartsPreviousStepRequest = async (cartId: number | undefined) => {
  return await requestAxios.put(`carts_previous_step/${cartId}`);
};

// Shipping Confirmation Next Step
const ShippingConfirmationNextStep = async (cartId: number) => {
  return await requestAxios.put(`checkout/shipping_confirmation/${cartId}`, {
    cart_id: cartId,
  });
};

// Shipping Confirmation Next Step
const IdentityVerificationNextStep = async (cartId: any, nextStep: string) => {
  return await requestAxios.put(`checkout/identity_verifications/${cartId}`, {
    cart_id: cartId,
    next_step: nextStep,
  });
};

// Add Actively Medication request
const addActivelyTakingMedicationsRequest = async (values: {
  visit_uuid: string;
}) => {
  return await requestAxios.post(
    `ui/add_medications`, {
      visit_uuid: values.visit_uuid,
      values: values
    }
  );
};

// Delete Medication request
const deleteActivelyTakingMedicationsRequest = async (values: {
  visit_uuid: string;
}) => {
  return await requestAxios.post(
    `ui/delete_medications`,{
      visit_uuid: values.visit_uuid,
      values: values
    }
  );
};

// Add Treatments request
const addTreatmentsRequest = async (values: { visit_id: string }) => {
  return await requestAxios.post(
    `ui/add_treatments`, {
      visit_uuid: values.visit_id,
      values: values
    }
  );
};

// Delete Treatments request
const deleteTreatmentsRequest = async (values: { visit_id: string }) => {
  return await requestAxios.post(
    `ui/delete_treatments`, {
      visit_uuid: values.visit_id,
      values: values
    }
  );
};

// Otp code verification request
const OtpCodeVerificationRequest = async (code: string) => {
  return await requestAxios.post(`otp/code_verification`, {
    code: code,
  });
};

// Generate Otp Code request
const GenerateOtpRequest = async () => {
  return await requestAxios.post(`otp`);
};

const SsnVerificationRequest = async (ssnCode: string, cartID: any) => {
  return await requestAxios.post(`ssn`, {
    ssn_code: ssnCode,
    cart_id: cartID,
  });
};

const SsnVisitContinueRequest = async (cartID: any) => {
  return await requestAxios.put(`ssn/continue_visit`, {
    cart_id: cartID,
  });
};

const FetchDiscountTemplate = async (code: string) => {
  return await requestAxios.get("discount_templates", {
    params: {
      code: code,
    },
  });
};

export {
  LoginRequest,
  GetUserRequest,
  UpdateUserRequest,
  UpdateUserPasswordRequest,
  ForgotPasswordRequest,
  ValidateResetPasswordTokenRequest,
  SetNewPasswordRequest,
  SignupRequest,
  VerifyEmailRequest,
  ValidateSendGridEmailRequest,
  GetProductVariantRequest,
  CreateCartRequest,
  CreateSlugCartRequest,
  FetchCartRequest,
  UpdateCartRequest,
  DestroyCartRequest,
  WelcomePageRequest,
  FetchIntroQuestionsRequest,
  SendIntroQuestionsToPocketmedRequest,
  FetchVisitConsentDataRequest,
  FetchStatesRequest,
  CreateVisitRequest,
  UpdateIntroQuestionsRequest,
  VisitConsentNextStepRequest,
  PatientInfoNextStepRequest,
  VisitIntroNextStepRequest,
  FetchQuestionsRequest,
  QuestionnaireNextStepRequest,
  SendUserResponseToPocketmedRequest,
  FetchDosespotDataRequest,
  GetProductVariantsRequest,
  ProductDetailNextStepRequest,
  ProductDetailConversionRequest,
  ShippingNextStepRequest,
  FetchShippingAddressesRequest,
  FetchDeliveryOptonsRequest,
  AddShippingAddressRequest,
  UpdateShippingAddressRequest,
  DeleteShippingAddressRequest,
  FetchIdFromPockedmedRequest,
  UploadVisitIdRequest,
  IdUploadNextStepRequest,
  SelfieNextStepRequest,
  UploadVisitSelfieRequest,
  FetchSelfieFromPockedmedRequest,
  QuestionnaireBackRequest,
  NoCheckupNextStepRequest,
  NoBloodPressureNextStepRequest,
  GetPocketmedStatusesRequest,
  FetchMedicalVisitsRequest,
  FetchCardsRequest,
  CreateCardRequest,
  SelectCardRequest,
  DeleteCardRequest,
  PaymentNextStepRequest,
  ApplyDiscountRequest,
  RemoveDiscountRequest,
  ResumeCartRequest,
  FetchOrderRequest,
  OrderPaymentRequest,
  RetakePhotosStatusRequest,
  ResetPhotosRetakeRequest,
  RetakePhotosNextStepRequest,
  FetchOrderRefillsRequest,
  CreateRefillOrderRequest,
  FetchRefillCartRequest,
  UpdateRefillOrderRequest,
  DeleteRefillOrderCartRequest,
  FetchRefillContactStageCartRequest,
  GetChatModalIframUrl,
  MarkMessagesAsRead,
  GetOrderStatusRequest,
  GetCurrentOrders,
  GetOrdersHistory,
  CreateFacebookUserRequest,
  FinishOauthSetupRequest,
  MelissaAutocompleterequest,
  FetchRecentMostCartRequest,
  ResendEmailConfirmationRequest,
  UserEmailConfirmationRequest,
  GetProductDetailsRequest,
  ResetQuestionnaireRequest,
  CartsPreviousStepRequest,
  ShippingConfirmationNextStep,
  OrderUpdateRequest,
  MarketingNextStepRequest,
  addActivelyTakingMedicationsRequest,
  deleteActivelyTakingMedicationsRequest,
  addTreatmentsRequest,
  deleteTreatmentsRequest,
  OtpCodeVerificationRequest,
  GenerateOtpRequest,
  SsnVerificationRequest,
  IdentityVerificationNextStep,
  SsnVisitContinueRequest,
  LoginViaMobileRequest,
  LoginViaEmailRequest,
  EmailCodeRequest,
  NewShippingAddressRequest,
  ShippingAddressSuggestionsRequest,
  LogoutRequest,
  FetchDiscountTemplate,
};
