import React, { useContext, useState } from "react";
import StateInput from "../../../components/StateInput";
import Button from "../../../components/Button";
import HOCPhoneNumberMask from "../../../components/HOCPhoneNumberMask";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
  AddShippingAddressRequest,
  UpdateShippingAddressRequest,
  NewShippingAddressRequest,
  ShippingAddressSuggestionsRequest,
} from "../../../apis";
import { AddressType } from "../../../types";
import { AuthContext } from "../../../Contexts/AuthContext";
import { isEmpty } from "lodash";
import DeliveryInfoModal from "containers/Shared/DeliveryInfoModal";
import Translator from "components/Translator";
import { NAME_REGEX } from "helpers/Constants";

const ShippingFormSchema = Yup.object().shape({
  first_name: Yup.string().required("Required").matches(NAME_REGEX, "Must enter a proper name"),
  last_name: Yup.string().required("Required").matches(NAME_REGEX, "Must enter a proper name"),
  street_1: Yup.string().required("Required"),
  street_2: Yup.string(),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
  phone: Yup.string().min(14, "Must enter proper number").required("Required"),
});

type NewShippingAddressFormProp = {
  handleClose?: any;
  address?: AddressType;
  editAddress?: Boolean;
  setAddress: any;
};

const NewShippingAddressForm = ({
  handleClose,
  address,
  editAddress,
  setAddress,
}: NewShippingAddressFormProp) => {
  const user = useCurrentUser();
  const { reloadUser } = useContext(AuthContext);
  const [selectedState, setSelectedState] = useState<string>(
    address?.state || ""
  );
  const [suggestedAddress, setSuggestedAddress] = useState<AddressType>({});
  const [showDeliveryInfoModal, setShowDeliveryInfoModal] =
    useState<boolean>(false);
  const [deliveryInfoModalType, setDeliveryInfoModalType] = useState(
    "suggested" || "not found"
  );
  const [smartyResults, setSmartyResults] = useState<[]>([]);
  const [isSmartyDropdown, setIsSmartyDropDown] = useState<boolean>(false);
  const [smartyState, setSmartyState] = useState<string>(selectedState);
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<string>("");
  const [stateError, setStateError] = useState<string>("");

  const handleStateChange = (code: any, setFieldValue: any) => {
    setSelectedState(code);
    setFieldValue("state", code);
  };

  const handleNewShippingAddress = (values: any) => {
    NewShippingAddressRequest(values)
      .then((res) => {
        const {
          invalid,
          invalid_address,
          invalid_secondary_number,
          suggested_address,
          suggestion,
        } = res.data.result;

        if (!isEmpty(suggested_address)) {
          setSuggestedAddress(suggested_address);

          if (
            values.street_1 === suggested_address.street_1 &&
            values.city === suggested_address.city &&
            values.state === suggested_address.state &&
            values.zip === suggested_address.zip &&
            values.street_2 == suggested_address.street_2 &&
            !invalid_secondary_number
          ) {
            handleSubmitAddress(values);
          } else {
            setDeliveryInfoModalType("suggested");
            setShowDeliveryInfoModal(true);
          }
        } else if (!isEmpty(invalid_address)) {
          setDeliveryInfoModalType("not found");
          setShowDeliveryInfoModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitAddress = (values: AddressType) => {
    const apiCall = editAddress
      ? UpdateShippingAddressRequest(values)
      : AddShippingAddressRequest(values);

    apiCall
      .then((res) => {
        reloadUser();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setPhoneError(err.response.data.errors.phone)
        setStateError(err.response.data.errors.state)
      });
  };

  const smartyAutocomplete = (e: any) => {
    const value = e.target.value;

    setLoading(true);

    if (value.length > 3) {
      const searchText: string = value;
      const maxRecords: number = 10;
      const country: string = "US";

      setIsSmartyDropDown(true);
      getShippingSuggestions(searchText, "");
    } else if (value.length === 0) {
      setIsSmartyDropDown(false);
    }
  };

  const getShippingSuggestions = (searchText: string, selected: string) => {
    ShippingAddressSuggestionsRequest(searchText, selected)
    .then((res) => {
      setSmartyResults(res.data.result);
      setLoading(false);
    })
    .catch((err) => console.log(err));
  }
  
  const formatAddressValue = (data: any) => {
    return `${data.street_line} ${data.secondary} (${data.entries}) ${data.city} ${data.state} ${data.zipcode}`
  }

  const handleResultClick = (data: any, setFieldValue: any) => {
    if (data.entries > 1) {
      setLoading(true);
      const formattedValue = formatAddressValue(data);
      getShippingSuggestions(data.street_line, formattedValue);
    } else {
      const newAddress = {
        ...address,
        street_1: data?.street_line,
        street_2: data?.secondary,
        city: data?.city,
        state: data?.state,
        zip: data.zipcode || "",
      };
  
      setAddress({ ...address, ...newAddress });
      setSelectedState(data.state);
      setFieldValue("street_1", data.street_line);
      setFieldValue("street_2", data.secondary);
      setFieldValue("city", data.city);
      setFieldValue("state", data.state);
      setFieldValue("zip", data.zipcode || "");
      setSmartyState(data.state);
      handleStateChange(data.state, setFieldValue);
  
      setIsSmartyDropDown(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          first_name: address?.first_name
            ? address.first_name
            : user?.first_name,
          last_name: address?.last_name ? address.last_name : user?.last_name,
          street_1: address?.street_1 || "",
          street_2: address?.street_2 || "",
          city: address?.city || "",
          state: address?.state || "",
          zip: address?.zip || "",
          phone: address?.phone || "",
          id: address?.id,
        }}
        validationSchema={ShippingFormSchema}
        onSubmit={(values) => {
          setAddress({ ...address, ...values });

          handleNewShippingAddress(values);
        }}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <>
            <Form className="shipping-address__form">
              <div className="form-group field">
                <label className="input-label">First Name</label>
                <div className="input-group row mx-0">
                  <div className="input__icon user"></div>
                  <Field
                    className="input with-icon no-mouseflow mosueflow-hidden"
                    name="first_name"
                  />
                </div>
                {errors.first_name && touched.first_name ? (
                  <div className="input-error">{errors.first_name}</div>
                ) : null}
              </div>

              <div className="form-group field">
                <label className="input-label">Last Name</label>
                <div className="input-group row mx-0">
                  <Field
                    className="input with-icon no-mouseflow mosueflow-hidden"
                    name="last_name"
                  />
                  <div className="input__icon user"></div>
                </div>
                {errors.last_name && touched.last_name ? (
                  <div className="input-error">{errors.last_name}</div>
                ) : null}
              </div>
              <div className="form-group field">
                <label className="input-label">Street</label>
                <div className="input-group row mx-0">
                  <Field
                    className="input with-icon no-mouseflow mosueflow-hidden"
                    name="street_1"
                    onKeyUp={(e: any) => smartyAutocomplete(e)}
                    onFocus={() => setIsSmartyDropDown(true)}
                    onBlur={() => setIsSmartyDropDown(loading)}
                  />
                  <div className="input__icon address"></div>
                </div>
                <div className="search-addresses">
                  {isSmartyDropdown && (
                    <ul className="melissa-drowdown">
                      {loading && (
                        <span className="p-3">
                          <Translator path="checkout.shipping.loading" />
                        </span>
                      )}

                      {!loading && smartyResults?.length === 0 ? (
                        <span className="p-3 mouseflow-hidden">
                          <Translator path="checkout.shipping.noResults" />
                        </span>
                      ) : (
                        <>
                          {smartyResults.map((result: any, key: number) => (
                            <>
                              <li
                                key={key}
                                className="no-mouseflow mouseflow-hidden"
                              >
                                <button
                                  className="no-mouseflow mouseflow-hidden"
                                  type="button"
                                  onMouseDown={() =>
                                    handleResultClick(result, setFieldValue)
                                  }
                                >
                                  {result.street_line} {result.secondary}{" "}
                                  {result.entries > 0 && (
                                    <>
                                      {`(${result.entries} or more entries)`}{" "}
                                    </>
                                  )}
                                  {result.city}, {result.state} {result.zipcode}
                                </button>
                              </li>
                            </>
                          ))}
                        </>
                      )}
                    </ul>
                  )}
                </div>
                {errors.street_1 && touched.street_1 ? (
                  <div className="input-error">{errors.street_1}</div>
                ) : null}
              </div>

              <div className="form-group field">
                <label className="input-label">Apt/Unit/Suite</label>
                <div className="input-group row mx-0">
                  <Field
                    className="input with-icon no-mouseflow mosueflow-hidden"
                    name="street_2"
                  />
                  <div className="input__icon address"></div>
                </div>
                {errors.street_2 && touched.street_2 ? (
                  <div className="input-error">{errors.street_2}</div>
                ) : null}
              </div>

              <div className="form-group field">
                <label className="input-label">City</label>
                <div className="input-group row mx-0">
                  <Field
                    className="input with-icon no-mouseflow mosueflow-hidden"
                    name="city"
                  />
                  <div className="input__icon city"></div>
                </div>
                {errors.city && touched.city ? (
                  <div className="input-error">{errors.city}</div>
                ) : null}
              </div>

              <div className="form-group field">
                <label className="input-label">State</label>
                <Field name="state">
                  {({ field }: any) => {
                    return (
                      <StateInput
                        value={selectedState}
                        smartyState={smartyState}
                        setError={setStateError}
                        handleStateChange={(stateCode: string) =>
                          handleStateChange(stateCode, setFieldValue)
                        }
                        {...field}
                      />
                    );
                  }}
                </Field>
                {errors.state && touched.state ? (
                  <div className="input-error">{errors.state}</div>
                ) : null}
                {stateError && (
                  <div className="input-error">
                    {`State ${stateError}`}
                  </div>
                )}
              </div>

              <div className="form-group field">
                <label className="input-label">ZIP</label>
                <div className="input-group row mx-0">
                  <Field
                    className="input with-icon no-mouseflow mosueflow-hidden"
                    name="zip"
                  />
                  <div className="input__icon zip"></div>
                </div>
                {errors.zip && touched.zip ? (
                  <div className="input-error">{errors.zip}</div>
                ) : null}
              </div>

              <div className="form-group field">
                <label className="input-label">
                  Phone number (For your privacy mobile is preferred)
                </label>
                <HOCPhoneNumberMask
                  name="phone"
                  setError={setPhoneError}
                />
                {errors.phone && touched.phone ? (
                  <div className="input-error">{errors.phone}</div>
                ) : null}
                {phoneError && (
                  <div className="input-error">
                    {`Phone ${phoneError}`}
                  </div>
                )}
              </div>

              <div className="form-actions">
                <Button type="reset" color="primary" onClick={handleClose}>
                  Cancel
                </Button>

                <Button type="submit" color="secondary" size="large">
                  SAVE
                </Button>
              </div>
            </Form>

            <DeliveryInfoModal
              show={showDeliveryInfoModal}
              setShow={setShowDeliveryInfoModal}
              selectedAddress={address}
              suggestedAddress={suggestedAddress}
              handleSubmitAddress={(values) => handleSubmitAddress(values)}
              setAddress={setAddress}
              deliveryInfoModalType={deliveryInfoModalType}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default NewShippingAddressForm;
